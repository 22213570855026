interface dateTimeElementStyles {
    NUMERIC: 'numeric';
    TWO_DIGIT: '2-digit';
    LONG: 'long';
    SHORT: 'short';
}

export const DATETIME_ELEMENT_STYLES: dateTimeElementStyles = {
    NUMERIC: 'numeric',
    TWO_DIGIT: '2-digit',
    LONG: 'long',
    SHORT: 'short',
};

export const DATETIME_FORMATTING_OPTIONS = {
    year: {},
    month: {},
    day: {},
    hour: {},
    minute: {},
    second: {},
    dateStyle: {},
    hour12: {},
};
