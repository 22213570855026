import React, { forwardRef } from 'react';
import classNames from 'classnames';
import BaseButton from '../BaseButton';
import { BaseButtonProps } from '../BaseButton/types';
import classes from './index.module.scss';

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Omit<BaseButtonProps, 'shape' | 'hasIntent'>>(
  (
    {
      children,
      disabled = false,
      intent = 'primary',
      size = 'md',
      variant = 'filled',
      loading = false,
      className: customClassName,
      ...rest
    },
    ref
  ) => {
    const className = classNames(classes[`size-${size}`], customClassName);

    return (
      <BaseButton
        {...rest}
        variant={variant}
        intent={intent}
        loading={loading}
        disabled={disabled}
        ref={ref}
        className={className}
      >
        {children}
      </BaseButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
