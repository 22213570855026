export const callOnActive = (handler) => (active) => active && handler();

export const setCookie = ({ name, value, domain, days = 0 }) => {
    let expiresEntry = '';
    let domainEntry = '';
    if (days) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        expiresEntry = `; expires=${date.toGMTString()}`;
    }
    if (domain) {
        domainEntry = `; domain=${domain}`;
    }

    document.cookie = `${name}=${value}${expiresEntry}${domainEntry}; path=/`;
};

export const getCookie = (name, defaultValue) => {
    let cookieValue;

    const cookieEntry = document.cookie.split(';').find((row) => row.trim().startsWith(`${name}=`));

    if (cookieEntry) {
        cookieValue = cookieEntry.split('=')[1];
    } else if (defaultValue !== undefined) {
        cookieValue = defaultValue;
    } else {
        cookieValue = null;
    }

    try {
        return JSON.parse(cookieValue);
    } catch (err) {
        return cookieValue;
    }
};
