import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { SpinnerAnimatedIcon } from '@fiverr-private/icons';
import { BaseButtonProps } from './types';
import classes from './index.module.scss';
import useGetButtonElement from './hooks/useGetButtonElement';

const BaseButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, BaseButtonProps>(
  (
    {
      children,
      href,
      loading = false,
      disabled = false,
      className: customClassName,
      intent = 'primary',
      shape = 'square',
      variant = 'filled',
      hasIntent = true,
      ...rest
    },
    ref
  ) => {
    const { element: Element, role } = useGetButtonElement(href);

    const className = classNames(
      classes.button,
      classes[`variant-${variant}`],
      classes[`shape-${shape}`],
      { [classes[`intent-${intent}`]]: hasIntent },
      { [classes.loading]: loading },
      { [classes.disabled]: disabled },
      customClassName
    );

    return (
      <Element {...rest} role={role} ref={ref} href={href} disabled={disabled} className={className}>
        {children}
        {loading && (
          <span className={classes.loadingIcon}>
            <SpinnerAnimatedIcon color="currentColor" />
          </span>
        )}
      </Element>
    );
  }
);

BaseButton.displayName = 'BaseButton';

export default BaseButton;
